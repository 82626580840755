var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cabecalho-pagina',{attrs:{"titulo":_vm.$t('modulos.certificado_interno.titulos.listagem'),"titulo-botao-novo":_vm.$t('modulos.certificado_interno.titulos.novo'),"total-registros":_vm.tabela.quantidadeItens,"sem-botao-ok":_vm.participanteLogadoConta &&
        _vm.buscarPermissao('CertificadoInterno', 'Inserir'),"sem-filtro":!_vm.buscarPermissao('CertificadoInterno', 'Listar')},on:{"abrirNovo":_vm.abrirNovo,"filtrar":_vm.filtrar},scopedSlots:_vm._u([{key:"filtro",fn:function({ filtro }){return [_c('filtro',{attrs:{"filtro":filtro}})]}}])}),_c('tabela-padrao-prime-vue',{staticClass:"mt-2",attrs:{"filtros-com-colunas":"","colunas":_vm.tabela.colunas,"dados":_vm.tabela.dados,"mostrar-acoes":"","selecionar-colunas":"","sem-selecionar-todos":"","quantidade-itens":_vm.tabela.quantidadeItens,"por-pagina":_vm.tabela.porPagina,"quantidade-paginas":_vm.tabela.quantidadePaginas,"pagina":_vm.tabela.paginaAtual,"mostrar-seletor":false},on:{"paginar":_vm.listarRegistros,"filtrarGlobal":_vm.filtrarGlobal,"filtroGlobal":_vm.filtroGlobal},scopedSlots:_vm._u([{key:"acoes",fn:function({ slotProps }){return [_c('dropdown-padrao',{attrs:{"text":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-icon',[_vm._v(" $dotsVertical ")])]},proxy:true}],null,true)},[(!_vm.participanteLogadoConta)?_c('dropdown-padrao-item',{attrs:{"disabled":!_vm.podeEditar(slotProps.data)},on:{"click":function($event){return _vm.confirmacaoAbrirCalibracao(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.editar'))+" ")]):_vm._e(),(_vm.buscarPermissao('CertificadoInterno', 'Visualizar'))?_c('dropdown-padrao-item',{on:{"click":function($event){return _vm.visualizarCalibracao(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.certificado_interno.botoes.visualizar_calibracao'))+" ")]):_vm._e(),(_vm.buscarPermissao('CertificadoInterno', 'Visualizar'))?_c('dropdown-padrao-item',{on:{"click":function($event){return _vm.abrirCertificado(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.certificado_interno.botoes.visualizar_certificado'))+" ")]):_vm._e(),(
            !_vm.participanteLogadoConta &&
              _vm.buscarPermissao('CertificadoInterno', 'Assinar')
          )?_c('dropdown-padrao-item',{attrs:{"tooltip":_vm.tooltipAssinar(slotProps.data),"disabled":!_vm.podeAssinar(slotProps.data)},on:{"click":function($event){return _vm.assinarCertificado(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.certificado_interno.botoes.assinar_certificado'))+" ")]):_vm._e(),(
            !_vm.participanteLogadoConta &&
              _vm.buscarPermissao('CertificadoInterno', 'Cancelar')
          )?_c('dropdown-padrao-item',{attrs:{"disabled":!_vm.podeCancelar(slotProps.data)},on:{"click":function($event){return _vm.cancelarCalibracao(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.certificado_interno.botoes.cancelar_certificado'))+" ")]):_vm._e(),(
            !_vm.participanteLogadoConta &&
              _vm.buscarPermissao('CertificadoInterno', 'AvaliarCertificado')
          )?_c('dropdown-padrao-item',{attrs:{"disabled":!_vm.podeAvaliar(slotProps.data)},on:{"click":function($event){return _vm.avaliarCertificado(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.certificado_interno.botoes.avaliar'))+" ")]):_vm._e(),(_vm.buscarPermissao('CertificadoInterno', 'Visualizar'))?_c('dropdown-padrao-item',{attrs:{"disabled":slotProps.data.situacaoCertificado != 'Assinado'},on:{"click":function($event){return _vm.abrirGraficoTendecia(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.instrumento.botoes.grafico_tendencia'))+" ")]):_vm._e()],1)]}},{key:"situacaoCertificado",fn:function({ slotProps }){return [_c('div',{class:`${
          _vm.formatarSituacaoCertificado(slotProps.data.situacaoCertificado)?.cor
        }--text`},[_vm._v(" "+_vm._s(_vm.formatarSituacaoCertificado(slotProps.data.situacaoCertificado) ?.text)+" ")])]}},{key:"situacaoAvaliacao",fn:function({ slotProps }){return [_c('div',{class:`${
          _vm.formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.cor
        }--text`},[_vm._v(" "+_vm._s(_vm.formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.text)+" ")])]}}]),model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }