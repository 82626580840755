<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.codigoInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.codigo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeTipoInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.nome_tipo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeCliente"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.nome_cliente')"
      em-filtro
    />

    <input-select
      v-model="filtro.tipoCalibracaoId"
      :options="opcoes.tiposCalibracao"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.tipo_calibracao')"
      :placeholder="$t('geral.inputs.selecione')"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.situacao"
      :options="opcoes.situacoesCertificado"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.situacao')"
      :placeholder="$t('geral.inputs.selecione')"
      em-filtro
      retornar-objeto
    />
    <input-date
      v-model="filtro.calibracaoEm"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.calibrado_em')"
      em-filtro
    />
    <input-select
      v-model="filtro.SituacaoAvaliacao"
      :options="opcoes.situacoesaAvaliacao"
      class="col-12 col-md-6"
      :label="$t('modulos.certificados.filtro.situacao_avaliacao')"
      :placeholder="$t('geral.inputs.selecione')"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tiposCalibracao: [],
        situacoesCertificado: [],
        situacoesaAvaliacao: [],
      },
    };
  },
  mounted() {
    this.buscarTiposCalibracao();
    this.buscarEnumerador('EnumSituacaoCertificado', 'situacoesCertificado');
    this.buscarEnumerador('EnumSituacaoAvaliacao', 'situacoesaAvaliacao');
  },
  methods: {
    buscarTiposCalibracao: function () {
      TipoCalibracaoService.listar().then((res) => {
        this.opcoes.tiposCalibracao = new DropdownModel(res.data);
      });
    },
    buscarEnumerador: function (nomeEnumerador, variavelOptions) {
      EnumeradorService.buscar(nomeEnumerador).then((res) => {
        this.opcoes[variavelOptions] = res;
      });
    },
  },
};
</script>
